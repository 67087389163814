import {mapActions, mapGetters} from 'vuex';

import CompanyService from '@/services/company.service';
import DataService from '@/services/data.service';

const service = new DataService();
const companyService = new CompanyService();

export default {
  name: 'upload',

  data() {
    return {
      file: '',
      status: '',
      fileData: {
        legalDocument: null,
        fieldsToMap: [],
      },

      companySelected: null,
      companies: [],
      sectionsSelected: [],
      sections: [
        {value: 'appraisal', text: this.$t('appraisal')},
        {value: 'customer', text: this.$t('customer')},
      ],
    }
  },

  async mounted() {
    this.setUploadImportFileData(null);
    await this.listCompanies()
  },

  methods: {
    ...mapActions([
        'setUploadImportFileData'
    ]),

    ...mapGetters([
      'getUploadImportFileData'
    ]),

    async setFile(event) {
      const reader = event.target.files;
      if (reader.length > 0) this.file = reader[0];
      this.status = 'loading-1';
      await this.upload();
    },

    async upload() {
      const formData = new FormData();

      formData.append('file', this.file);
      this.status = 'loading-2';

      const res = await service.createLegalDocument(formData);
      if (res && !res.error) {
        this.fileData = res.data;
        this.$notify({type: 'success', message: this.$t('process_import_success')});
        this.status = 'completed';
      } else {
        this.status = 'error';
      }
    },

    removeFile() {
      //fileService.removeFile(this.file.path);
      this.setUploadImportFileData(null);
      this.file = '';
    },

    async listCompanies() {
      const res = await companyService.list();
      if (res && !res.error) {
        this.companies = res.data.companies;
      }
    },
  },

  watch: {
    async companySelected(value) {
      this.fileData.legalDocument.Company = value;
      await this.setUploadImportFileData(this.fileData);
    },

    async sectionsSelected(value) {
      this.fileData.legalDocument.showInSections = value;
      await this.setUploadImportFileData(this.fileData);
    }
  }
}